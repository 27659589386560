import './developer-info.scss';
import { autoinject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router, RouterEvent, NavigationInstruction } from 'aurelia-router';

import { connectTo } from 'aurelia-store';
import { ApplicationState } from 'lib/state';

type RouteInfo = {
  title: string;
  fragment: string;
  allowedRoles: string[];
  route?: string;
  queryString?: string;
  name?: string;
};

@autoinject
@connectTo()
export class DeveloperInfo {
  protected showDeveloperInfo: boolean;
  protected state: ApplicationState;

  protected routeInfo: RouteInfo = {
    title: '',
    fragment: '',
    allowedRoles: [],
  };

  constructor(
    protected router: Router,
    private eventAggregator: EventAggregator
  ) {
    window.addEventListener('keydown', this.toggleDeveloperInfo.bind(this));
  }

  private toggleDeveloperInfo = (e: KeyboardEvent) => {
    if (e.key === '.' && e.ctrlKey) {
      this.showDeveloperInfo = !this.showDeveloperInfo;
    }
  };

  protected close() {
    this.showDeveloperInfo = false;
  }

  public attached() {
    this.eventAggregator.subscribe(RouterEvent.Complete, (event: { instruction: NavigationInstruction }) => {
      const routeConfig = event.instruction.config;
      const routeSettings = routeConfig.settings;

      let route = '';
      if (Array.isArray(routeConfig.route)) {
        route = routeConfig.join(', ');
      } else {
        route = routeConfig.route;
      }

      const routeInfo = {
        title: routeConfig.title,
        name: routeConfig.name,
        route: route,
        fragment: event.instruction.fragment,
        queryString: event.instruction.queryString,
        params: JSON.stringify(event.instruction.params),
        allowedRoles: [],
      };

      if (routeSettings && routeSettings.roles) {
        routeInfo.allowedRoles = routeSettings.roles;
      }
      this.routeInfo = routeInfo;
    });
  }
}
