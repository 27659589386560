import { autoinject } from 'aurelia-framework';
import { ToastService } from 'services/toast-service';

@autoinject
export class adminOverview {
  private adminViews: Array<Object> = [];
  constructor(
    private toastService: ToastService
  ) {
    this.adminViews = [
      {
        'name-t': 'admin.system_administration',
        'views': [
          {
            "t": 'admin.user_administration',
            "route": 'user-list',
          },
          {
            "t": 'general.servicestation',
            "route": 'service-station-list',
          }
        ]
      }
    ]
  }

  private showAppexMessage() {
    this.toastService.showError('general.controlledByAppex');
  }

}
