import { autoinject, PLATFORM } from 'aurelia-framework';
import { Router, RouterConfiguration } from 'aurelia-router';
import './login.scss';

@autoinject
export class LoginMain {
  private router: Router;

  constructor(router) {}

  private configureRouter(config: RouterConfiguration, router) {
    config.map([
      {
        route: [''],
        name: 'login-form',
        moduleId: PLATFORM.moduleName('./login-form')
      },
      {
        route: ['mfa'],
        name: 'login-mfa',
        moduleId: PLATFORM.moduleName('./login-mfa')
      },
      {
        route: ['forgot-password'],
        name: 'login-forgot-password',
        moduleId: PLATFORM.moduleName('./login-forgot-password')
      },
      {
        route: ['reset-password/:email/:code'],
        name: 'login-reset-password',
        moduleId: PLATFORM.moduleName('./login-reset-password')
      },
      {
        route: ['loggedout'],
        name: 'login-loggedout',
        moduleId: PLATFORM.moduleName('./login-loggedout')
      }
    ]);
    this.router = router;
  }
}
