import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ForgotPasswordTo } from 'models/forgotPasswordTo';
import { Message } from 'models/Message';
import { AccountService } from 'services/account-service';

@autoinject
export class LoginForgotPassword {
  private message: Message = new Message();
  private passwordRequested: boolean = false;

  constructor(
    private accountService: AccountService,
    private forgotPasswordTo: ForgotPasswordTo,
    private router: Router
  ) {
  }

  private activate(params, routeData) {
    this.forgotPasswordTo.Email = routeData.Email;
  }

  private forgotPassword() {
    this.accountService.forgotPassword(this.forgotPasswordTo).then((response) => {
      this.passwordRequested = true;

      this.message = {
        messageKey: 'general.reset_password_link',
        messageClass: '',
        messageAutoCloseAfter: 0
      };
      window.setTimeout(() => this.router.navigateToRoute('login'), 5000);
    }).catch((err) => {
      alert('Error requesting new password');
    });

  }

}
