import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { AuthenticationResponse } from 'models/Authentication';
import { LoginData } from 'models/LoginData';
import { Message } from 'models/Message';
import { ResetPasswordDto } from 'models/resetPasswordDto';
import { AccountService } from 'services/account-service';
import { LoginShared } from './login-shared';

@autoinject
export class LoginResetPassword {
  private message: Message = new Message();
  private resetPasswordDto: ResetPasswordDto = new ResetPasswordDto();

  constructor(
    private accountService: AccountService,
    private ea: EventAggregator,
    private router: Router,
    private loginShared: LoginShared
  ) {}

  private activate(params, routeData) {
    this.resetPasswordDto.Email = params.email;
    this.resetPasswordDto.Code = params.code;
  }

  private createPassword() {
    this.message = new Message();

    this.accountService
      .resetPassword(this.resetPasswordDto)
      .then(() => {
        // log in user automatically after setting password
        const loginData = new LoginData();
        loginData.Email = this.resetPasswordDto.Email;
        loginData.Password = this.resetPasswordDto.Password;

        this.accountService
          .login(loginData)
          .then((loginResult: AuthenticationResponse) => {
            if (loginResult.loggedIn === true) {
              this.ea.publish('isLoggedIn', true);
              this.router.navigate('dashboard-main');
            } else if (loginResult.mfaRequired === true) {
              this.loginShared.email = loginData.Email;
              this.loginShared.mfaProviders = loginResult.mfaProviders;
              this.router.navigateToRoute('login-mfa');
            } else {
              this.message = {
                messageKey: 'general.incorrect_username_password',
                messageClass: 'error',
                messageAutoCloseAfter: 0,
              };

              window.setTimeout(
                () => this.router.navigateToRoute('login'),
                5000
              );
            }
          });
      })
      .catch(() => {
        this.message = {
          messageKey: 'general.reset_password_failed',
          messageClass: '',
          messageAutoCloseAfter: 0,
        };
      });
  }
}
