import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { AccountService } from 'services/account-service';
import './login-mfa.scss';
import { LoginShared } from './login-shared';
import { Logger } from 'aurelia-logging';
import { Message } from 'models/Message';

const logger: Logger = LogManager.getLogger('login-mfa');

interface MfaPageParams {
  code?: string;
  provider?: string;
  token?: string;
}

@autoinject
export class LoginMfa {
  private mfaProvider: string;

  public message: Message = new Message();
  public mfaProviders: string[];
  public mfaCode: string;
  public mfaCodeSent = false;
  public mfaToken = '';

  constructor(
    private eventAggregator: EventAggregator,
    private accountService: AccountService,
    private router: Router,
    private loginShared: LoginShared
  ) {
    if (loginShared.mfaProviders.length === 1) {
      this.mfaCodeSent = true;
      this.select(loginShared.mfaProviders[0]);
    }
  }

  public activate(params: MfaPageParams) {
    if (params.code && params.provider) {
      this.mfaCode = params.code;
      this.mfaProvider = params.provider;
      this.mfaToken = params.token;
      this.login();
    }

    logger.debug('shared', this.loginShared);
  }

  public select(mfaProvider) {
    return this.accountService.sendMfaCode(mfaProvider).then((response) => {
      if (response.codeSent) {
        this.message = {
          messageKey:
            mfaProvider === 'Email'
              ? 'general.mfaCodeSentEmail'
              : mfaProvider === 'Phone'
              ? 'general.mfaCodeSentPhone'
              : 'general.mfaCodeSent',
          messageClass: 'success',
          messageAutoCloseAfter: 3,
        };

        this.mfaToken = response.MfaToken;
        this.mfaProvider = mfaProvider;
        this.mfaCodeSent = true;
      } else {
        this.message = {
          messageKey: 'general.mfaCouldntSend',
          messageClass: 'error',
          messageAutoCloseAfter: 0,
        };
      }
    });
  }

  public login() {
    return this.accountService.mfaLogin(this.mfaProvider, this.mfaCode, this.mfaToken).then((result) => {
      if (result.loggedIn) {
        this.eventAggregator.publish('isLoggedIn', true);
        this.router.navigate('dashboard-main');
      } else {
        this.message = {
          messageKey: 'general.mfaInvalidCode',
          messageClass: 'error',
          messageAutoCloseAfter: 0,
        };
      }
    });
  }
}
