import { NavigationInstruction, Next, Redirect } from 'aurelia-router';
import { LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { Role, UserModels } from 'models/UserModels';

const logger: Logger = LogManager.getLogger('authorize-step');

export class AuthorizeStep {
  // substitute auth magic
  static auth: {
    isAuthenticated: boolean;
    roles: Role[];
    userData: UserModels.User;
  } = {
      isAuthenticated: false,
      roles: null,
      userData: null,
    };

  run(navigationInstruction: NavigationInstruction, next: Next): Promise<unknown> {
    const isLoggedIn = AuthorizeStep.auth.isAuthenticated;

    // currently active route config
    const currentRoute = navigationInstruction.config;

    if (!isLoggedIn && currentRoute.name.indexOf('login') !== 0) {
      logger.warn('redirect to login, not authenticated to show ' + currentRoute.name);
      return next.cancel(new Redirect('login'));
    }

    // check that the user has access to the currentRoute based on current RoleService
    if (currentRoute.settings && currentRoute.settings.roles && currentRoute.settings.roles.length > 0) {
      return this.getNavigationInstructions(currentRoute, next);
    } else {
      // no restrictions set for route, allow navigation
      return next();
    }
  }

  public getNavigationInstructions(currentRoute, next: Next) {
    // check that the user has one of the roles defined
    let isAuthorized = false;

    if (!AuthorizeStep.auth.roles) {
      console.error('Roles not loaded correctly');
      return next.cancel(new Redirect('noaccess'));
    }

    if (AuthorizeStep.auth.roles.indexOf('Administrator') !== -1) {
      isAuthorized = true;
    } else {
      currentRoute.settings.roles.forEach((allowedRole) => {
        if (AuthorizeStep.auth.roles.indexOf(allowedRole) !== -1) {
          isAuthorized = true;
        }
      });
    }

    if (isAuthorized) {
      // user has access to route, allow navigation
      return next();
    } else {
      return next.cancel(new Redirect('noaccess'));
    }
  }
}
