import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, containerless } from 'aurelia-framework';
import { bindable } from 'aurelia-framework';
import { Models } from 'models/core';
import { AddressService } from 'services/address-service';
import { Country, CountryService } from 'services/country-service';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';

@containerless
@autoinject
export class AddressFormInlineNew {
  @bindable private customerId: number;
  private address: Models.Address;
  protected countries: readonly Country[];

  constructor(
    private addressService: AddressService,
    private countryService: CountryService,
    private eventAggregator: EventAggregator,
    private toastService: ToastService,
    private errorService: ErrorService
  ) {}

  protected attached() {
    this.countries = this.countryService.getCountryList();
  }

  protected createAddress() {
    this.address.CustomerId = this.customerId;
    this.addressService
      .post(this.address)
      .then(() => {
        this.eventAggregator.publish('addressListReset', 1);
        this.eventAggregator.publish('addressFormNewClose', 1);
        this.toastService.showSuccess('address.created');
        this.address = new Models.Address();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  protected cancel() {
    this.eventAggregator.publish('addressFormNewClose', 1);
  }
}
