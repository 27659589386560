import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import {
  AuthenticationFailedResponse,
  AuthenticationResponse,
} from 'models/Authentication';
import { LoginData } from 'models/LoginData';
import { Message } from 'models/Message';
import { AccountService } from 'services/account-service';
import { LoginShared } from './login-shared';

@autoinject
export class LoginForm {
  private loginData: LoginData = new LoginData();
  private message: Message = new Message();
  constructor(
    private accountService: AccountService,
    private router: Router,
    private eventAggregator: EventAggregator,
    private loginShared: LoginShared
  ) {}

  private attached() {
    if (this.accountService.isAuthenticated) {
      this.router.navigateToRoute('dashboard');
    }
  }

  private login() {
    this.message.messageKey = '';

    this.accountService
      .login(this.loginData)
      .then((loginResult: AuthenticationResponse) => {
        if (loginResult.loggedIn === true) {
          this.eventAggregator.publish('isLoggedIn', true);
          this.router.navigate('dashboard-main');
        } else if (loginResult.mfaRequired === true) {
          this.loginShared.email = this.loginData.Email;
          this.loginShared.mfaProviders = loginResult.mfaProviders;
          this.router.navigateToRoute('login-mfa');
        } else {
          if ((loginResult as AuthenticationFailedResponse).noValidProviders) {
            this.message = {
              messageKey: 'general.missing2faProviders',
              messageClass: 'error',
              messageAutoCloseAfter: 0,
            };
          } else {
            this.message = {
              messageKey: 'general.incorrect_username_password',
              messageClass: 'error',
              messageAutoCloseAfter: 0,
            };
          }
        }
      });
  }

  private goToForgot() {
    const forgotRoute = this.router.routes.find(
      (x) => x.name === 'login-forgot-password'
    );
    forgotRoute.email = this.loginData.Email;
    this.router.navigateToRoute('login-forgot-password');
  }
}
